.dropdown-wrapper {
  position: relative;
  cursor: hand;
  cursor: pointer;
  @include inline-block();

  > a,
  > span,
  > i {
    padding: 0 1.5rem;
  }
  
  > .caret {
    padding: 0 0.2em;
  }
  
  &:before {
    right: 0;
    width: 250%;
    height: 3rem;
    display: block;
    bottom: -1.5rem;
    position: absolute;
  }

  .dropdown-block {
    right: 0;
    opacity: 0;
    padding: 1rem;
    cursor: default;
    font-size: 1rem;
    min-width: 12rem;
    text-align: left;
    margin-top: 1.5rem;
    position: absolute;
    pointer-events: none;
    background-color: $white;
    border: 1px solid $grayLight;
    transition: 0.25s;

    &:before,
    &:after {
      content: '';
      width: 0;
      height: 0;
      top: -5px;
      right: 20px;
      margin: 0 auto;
      display: block;
      position: absolute;
      background-color: transparent;
      border-left: 8px solid rgba(255, 255, 255, 0);
      border-right: 8px solid rgba(255, 255, 255, 0);
      border-bottom: 6px solid rgba(255, 255, 255, 0);
      -moz-transform: scale(1.01);
    }

    &:before {
      margin-top: -1px;
      border-bottom-color: inherit;
    }

    &:after {
      border-bottom-color: $white;
    }
  }

  /*-----------  Animations  -----------*/
  &:hover:not(.no-hover),
  &.activated {

    &:before {
      content: '';
    }

    .dropdown-block {
      opacity: 1;
      z-index: 16;
      margin-top: 1rem;
      pointer-events: auto;
    }
  }

  /*-----------  Dropdown Elements  -----------*/

  .dropdown-block {

    h6 {
      color: $gray;
      font-size: 0.85rem;
      padding-bottom: 0.25rem;
      margin: 0.5rem 0 0.75rem 0;
      text-transform: uppercase;
      border-bottom: 1px solid $grayLight;
    }

    a, span {
      color: $gray;
      display: block;
      margin: 0 -1rem;
      font-size: 0.85em;
      padding: 0.5rem 1rem;
      @include ellipsis();

      &:hover {
        color: $red;
      }

      &.active {
        font-weight: $fontWeightBold;
        color: $black;
      }

      > i {
        color: $grayLight;
        margin-right: 0.67rem;
        transition: all 0.15s ease-out 0s;
      }
    }

    > a, span {
      cursor: pointer;

      &:first-child {
        margin-top: -1rem;
        padding-top: 0.75rem;

        &.active {
          padding-top: 0.5rem;
        }
      }

      &:last-child {
        margin-bottom: -1rem;
        padding-bottom: 0.75rem;

        &.active {
          padding-top: 0.5rem;
        }
      }

      &:hover i {
        color: $red;
      }
    }

    ul {
      margin: unset;
      li {
        @include ellipsis();

        &.padded {
          padding-bottom: 2px;
        }

        > [class*='avatar'] {
          float: left;
        }

        a,span {
          text-align: left !important;
          padding: 0 0 0 1rem;
          @include inline-block(none);
        }
      }
    }

    .pill {
      @include inline-block();
      border-radius: 2px;
      font-size: 0.7em;
      padding: 1px 4px;
      text-transform: uppercase;
      float: right;
    }
  }
}
