/*-----------  Reveals  -----------*/
@import './variables';
@import './mixins';

.reveal-wrapper {
  cursor: pointer;
  overflow: hidden;
  margin-top: 0.75em;
  border-radius: $radiusLg;
  padding: 0.75em 1.15em 0;
  border: 2px solid $grayLightest;
  transition: all 0.15s ease-out 0s;

  i {
    color: $red !important;
    float: left;
    font-size: 3em;
    margin-right: 1rem;
  }

  button {
    i {
      color: white !important;
      font-size: 1em;
    }
  }

  .reveal-title {
    color: $red;
    font-size: 1.25em;
    @include antialiased();
  }

  .reveal-subtext {
    padding-left: 4em;
  }

  p {
    color: $gray;

    a {
      color: $blue;
      text-decoration: underline;
    }
  }

  .summary-info {
    flex-direction: column;
  }

  .reveal-block {
    clear: both;
    // max-height: 0em;
    overflow: hidden;
    padding: 0 0 0 4em;
    transition: ease 0.33s;

    .find-plugin {
      padding: 10px;
      color: $gray;
      a {
        color: #27AB9E;
        text-decoration: underline;
      }
    }

    &.no-pad {
      padding: 0;
    }

    .button {
      // @extend %green-button;
    }

    form#help-form {
      display: flex;
      flex-direction: row;

      .button {
        margin-left: 10px;
        place-self: center;
      }

      textarea {
        height: 5em;
      }
      textarea, input[type='text'] {
        width: 100%;
      }
      
    }
  }

  ul {
    color: $gray;
    margin-bottom: 1em;

    li {
      list-style: initial;
      margin-left: 1em;
    }
  }

  /*-----------  Animations  -----------*/

  &:hover,
  &.activated {
    box-shadow: $shadowSubtle;
    border-color: $grayLighter;
  }

  &.activated {
    cursor: default;

    .reveal-block {
      max-height: 33em;
      padding-top: 1em;
      padding-bottom: 1.25em;
    }
  }
}
