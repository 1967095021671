@import "./_variables.scss";

.Billing {
  margin-top: 2em;
  color: black;
}

.PlanInfo {
  .container {
    display: flex;
    justify-content: space-between;
  }

  .plan-name {
    font-weight: bold;
    font-size: 1.3em;
  }

  .plan-price {
    margin-top: 10px;
    font-size: 1.1em;
  }

  .all-plans-button {
    margin-left: 20px;
  }

  .plan-renew-on {
    margin-top: 10px;
  }

  .plan-upgrade {
    margin-top: 20px;
  }

  .plan-cancel {
    margin-top: 20px;
    text-decoration: underline;
    color: $gray;
    font-size: 0.8em;

    .plan-cancel-button {
      cursor: pointer;
    }
  }
}

.AddOnInfo {
  .add-on-name {
    font-weight: bold;
    font-size: 1.3em;
  }

  .add-on-buy-more {
    margin-top: 10px;
  }
}

.BillingInfo {
  margin-bottom: 20px;

  .TextLabel {
    color: $gray;
  }
}

.BillingInfo--editing {
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    max-width: 300px;

    & > .MuiFormControl-root,
    & > .MuiButton-root {
      margin-bottom: 1em;
    }
  }
}

.PayPalAccount {
  margin-top: 10px;
  img {
    width: 60px;
    margin-right: 1em;
  }
}
