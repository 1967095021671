@import 'variables';
@import 'mixins';
@import 'dropdowns';

/* new style */
.App > .header {
  z-index: 13;
  position: relative;
  min-width: 1440px;
  background-color: $red;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);
  @include clearfix();
  display: flex;

  .header-logo {
    float: left;
    width: $sidebarWidth;
    height: $headerHeight;

    img {
      max-width: 150px;
    }

    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button {
    height: 34px;
    border-radius: 4px;
    font-size: 14px;
    background-color: $white;
    color: #27ab9e;
  }

  .header-stats {
    border-right: 1px solid $orangeLight;
    height: $headerHeight;

    span {
      padding-right: 15px;
    }
  }

  .header-site-wrapper,
  .header-user-wrapper {
    color: $white;
    height: $headerHeight;
    padding-left: 15px;
    padding-right: 15px;

    .caret {
      img {
        float: right;
      }
    }
  }

  .header-site-wrapper {
    border-left: 1px solid $orangeLight;
    border-right: 1px solid $orangeLight;
    float: left;
    text-align: left;
    max-width: 285px;
    cursor: pointer;

    .site-flex {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .site-flex > div:first-child {
      flex: 0 1 100%;
    }
    .site-flex > div:nth-child(2) {
      flex: 1 1 70%;
    }

    .dropdown-box {
      min-width: 285px;
      margin: 10px 0px 0px -15px;
      padding: 10px 15px;
      transition: 0.25s;
      border: 1px solid $grayLight;
      font-size: 13px;
      position: relative;
      overflow: visible;

      &:after,
      &:before {
        content: '';
        width: 0;
        height: 0;
        top: -5px;
        right: 20px;
        margin: 0 auto;
        display: block;
        position: absolute;
        background-color: transparent;
        border-left: 8px solid rgba(255, 255, 255, 0);
        border-right: 8px solid rgba(255, 255, 255, 0);
        border-bottom: 6px solid rgba(255, 255, 255, 0);
        border-bottom-color: rgb(255, 255, 255);
        -moz-transform: scale(1.01);
      }

      > .caret {
        padding: 0 0.2em;
      }

      .dropdown_items {
        max-height: 350px;
        overflow: scroll;
        scrollbar-width: none;
      }
      .site-search {
        margin-bottom: 10px;
      }

      li {
        @include ellipsis();
        display: inherit;

        &.padded {
          padding: 10px 0px 5px;
          border-bottom: 1px solid $grayLighter;
        }

        a,
        span {
          text-align: left !important;
          color: $gray;
          font-size: 1em;
          padding: 0px 1px 0px 0px;
          width: 220px;
          @include inline-block(none);
          @include ellipsis();

          &:hover {
            color: $red;
          }

          &.active {
            font-weight: $fontWeightBold;
            color: $black;
          }
        }

        .pill {
          @include inline-block();
          border-radius: 2px;
          font-size: 0.7em;
          padding: 1px 4px;
          text-transform: uppercase;
          float: right;
          color: $white;
        }
      }

      .create-new-site {
        position: relative;
        margin-top: 10px;
        text-align: center;

        button {
          color: #e8562a;
          background: transparent;
          border: 1px solid #e8562a;
          font-weight: bold;
          text-transform: none;

          &:hover {
            color: #919191;
            border: 1px solid #919191;
            background-color: transparent;
          }
        }
      }
    }

    .site-name {
      color: $white;
      font-size: 16px;
      width: 265px;
      @include ellipsis();
      @include antialiased();
    }
  }

  .header-user-wrapper {
    min-width: 140px;
    margin-left: auto;
    display: flex;
    gap: 8px;

    .warning-banners {
      display: flex;
      margin-left: auto;
      .installation-warning {
        padding-left: 15px;
        display: inline-block;
        padding-top: 8px;
        justify-items: center;
        text-align: center;
        max-width: 300px;
        .banner {
          border: #ffffff 1px solid;
          border-radius: 7px;
          font-size: 12px;
          display: flex;
          background-color: $grayDark;
          .label {
            width: 80%;
            color: #ffffff;
            padding: 5px 3px;
            align-content: center;
          }
          i {
            position: absolute;
            margin-left: -20px;
            margin-top: 10px;
            font-size: 1.5em;
          }
          .target {
            background-color: #ffffff;
            color: $orange;
            font-weight: bold;
            padding: 5px 5px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            cursor: pointer;
          }
        }
      }

      .expired-trial-banner {
        padding-left: 15px;
        display: inline-block;
        padding-top: 8px;
        justify-items: center;
        text-align: center;
        max-width: 300px;
        .banner {
          border: #ffffff 2px solid;
          border-radius: 7px;
          font-size: 12px;
          display: flex;
          background-color: #ffffff;
          .label {
            width: 80%;
            color: $orange;
            padding: 5px 3px;
            align-content: center;
          }
          .target {
            background-color: $orange;
            color: #ffffff;
            font-weight: bold;
            padding: 5px 5px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }

    // > *:not(.button) {
    //   @include inline-block();
    // }

    .ctas {
      margin-top: 13px;
      height: $headerHeight;
      padding-right: 15px;
      min-width: 65px;

      .button {
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
        color: $grayDarker;
      }
    }

    .dropdown-wrapper {
      padding-left: 20px;
      margin: auto;

      .caret {
        img {
          padding-top: 15px;
        }
      }
    }

    .user-avatar {
      width: 34px;
      height: 34px;
      margin-right: 0.75em;
      border-radius: 5px;
      border: 1px solid $white;
      @include inline-block();
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
    }

    .user-name {
      color: $white;
      font-size: 18px;
      vertical-align: middle;
      display: none;
      @include antialiased();
      max-width: 280px;
      @media (min-width: 1300px) {
        display: none;
      }
    }

    .hellobar-changelogs {
      display: none;
      padding: 10px;
      margin: auto;
    }

    i.icon-notification,
    i.icon-notification-alert {
      font-size: 2.85em;
      margin-right: -0.67rem;
    }
  }

  .dropdown-wrapper > span {
    padding: 0;
  }
}

.App .expedition {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 111;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #e8562a;
  color: #ffffff;
  padding: 3px;
  img {
    width: 160px;
  }
  .hb-content {
    h5,
    h6 {
      color: #ffffff;
      text-transform: none;
      u {
        cursor: pointer;
      }
    }
  }
  .button-group {
    color: #ffffff;
    text-align: center;
    .button {
      font-size: 1.2em;
      font-weight: bold;
      border-radius: 25px;
    }
    .become-a-member {
      background-color: #ffffff;
      border: none;
      color: #e8562a;
    }
  }
}

.pill {
  border-radius: 2px;
  font-size: 0.7em;
  padding: 1px 4px;
  text-transform: uppercase;
  background-color: gray;

  &.ELT,
  &.ESP {
    background-color: $blue;
  }

  &.PRM,
  &.PSY {
    background-color: #513add;
  }

  &.pro-managed,
  &.STR {
    background-color: $purple;
  }

  &.pro-comped,
  &.CMP {
    background-color: $yellow;
  }

  &.GRW,
  &.PRO {
    background-color: $turtleGreen;
  }

  &.MNG,
  &.CU0,
  &.CU1,
  &.CU2,
  &.CU2 {
    background-color: $purple;
  }
}
