.DateRange {
  margin-right: 10px;
  .MuiSelect-root {
    padding-left: 5px;
  }
}

.CustomDateRange {
  margin-right: 0;

  .MuiFormControl-root {
    margin-right: 5px;
  }

  & .MuiOutlinedInput-input {
    padding: 11.5px 10px;
  }
}

.SummaryToolbox {
  height: 80px;
}
