@import "variables";
@import "./RuleEditor";

.EditRuleDialog {
  display: inline;

  &-handler {
    cursor: pointer;
  }

}

.EditRuleDialog-content {
  .MuiFormControlLabel-label {
    color: $red;
  }
  .MuiFormControlLabel-root {
    margin-right: 16px;
    width: 60px;
  }
  .matching {
    margin-top: 20px;
  }
}
