.PaymentMethod {
  display: flex;
  &--icon {
    margin-right: 10px;
  }

  &--last4 {
    margin-right: 10px;
  }

  &--update {
    margin-left: 20px;
    max-width: 300px;
  }

  .StripeElement--wrapper {
    margin: 20px 0;
    width: 100%;
  }
  .MuiButtonBase-root + .MuiButtonBase-root {
    margin-left: 1em;
  }
}
