@import '../styles/_variables.scss';

.NewContactList {
  .NameInput {
    margin-bottom: 20px;
  }

  .EmailEnableInput {
    margin-bottom: 12px;
    display: inline-block;
  }

  .EmailUsersInput {
    margin: 6px 0px 16px;
  }

  p {
    padding-bottom: 20px;
  }

  p,
  label,
  span {
    color: $gray;
    margin: 0;
  }

  .MuiDialogActions-root {
    justify-content: space-between;
    padding: 10px 24px 24px 24px;
  }

  .MuiDialog-paperWidthMd {
    max-width: 800px;
  }

  .MuiDialogTitle-root {
    text-align: center;
  }

  .HellobarProvider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 65%;
    margin: 10px 0;

    img {
      width: 60px;
      padding: 5px;
      border: 1px solid $grayLight;
      border-radius: 5px;
      margin-right: 10px;
    }
    p {
      font-size: 80%;
    }
  }

  .AllToolsButton {
    color: $blue;
    margin: 20px 0;
  }

  .Providers {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .Provider {
    margin: 10px 10px 10px 0px;
    text-align: center;
    img {
      max-width: 60%;
    }
    button {
      padding: 5px;
      border-radius: 5px;
      width: 120px;
      height: 120px;
      flex-direction: column;
      justify-content: space-evenly;
      background: rgba(216, 216, 216, 0.2);

      &:hover {
        background: rgb(255, 255, 213);
      }
    }
    .ProviderTitle {
      color: $textPrimary;
    }

    &.Provider--selected {
      button {
        background: rgb(255, 255, 213);
      }
    }
  }

  .ProviderSettings {
    justify-content: left;
    align-items: center;

    .MuiFormControl-fullWidth {
      margin-bottom: 20px;
    }

    .Form {
      margin-bottom: 20px;
    }
  }
}
