@use '../../shared/Button.scss';
@import './variables';
@import './mixins';

/*-----------  Conacts Dashboard  -----------*/

.invalid-field {
  border: 1px solid $orange;
  outline-style: double;
  outline-color: $orange;
}

[id^='contact_lists-'] {
  table {
    td {
      img {
        width: 2em;
        height: auto;
        margin-top: -3px;
        margin-bottom: -3px;
      }

      span + img {
        margin-left: 0.67em;
        @include inline-block();
      }
    }

    td:first-of-type {
      width: 35%;
    }

    td:last-of-type {
      width: 13em;
    }
  }
}

/*-----------  Conacts Group  -----------*/

#contact_lists-show {
  .info-block {
    margin: 0;
  }

  .spacer {
    margin-bottom: 2em;
  }

  .service-block {
    padding: 0.75em 1.33em;
    border: 1px solid $grayLighter;
    margin-bottom: 1em;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    flex-flow: row nowrap;
    display: flex;
    @include clearfix();

    .service-icon {
      width: 6.5em;
      margin-right: 1.33em;
      padding: 0.5em 1.67em 0.5em 0.33em;
      border-right: 1px solid $grayLighter;
    }

    .service-description {
      width: 100%;
      font-size: 1.15em;
      @include ellipsis();

      small {
        color: $gray;
        display: block;
        @include antialiased();
      }

      span {
        color: $grayDark;
      }
    }

    .service-actions {
      width: 28em;
      display: inline-flex;
      justify-content: space-between;

      form {
        margin-top: -1px;
        @include inline-block();
      }

      .button {
        font-size: 0.85em;
        margin-left: 0.5em;
        @extend .green-button !optional;
      }
    }
  }

  /*-----------  Table  -----------*/

  table {
    margin-top: 1em;

    td:first-of-type {
      width: 4.5em;
    }

    td[colspan] {
      border-left: none;
      border-right: none;
      border-bottom: none;
      text-align: center;

      * {
        font-style: italic;
      }
    }
  }

  /*-----------  Empty State  -----------*/

  .temporary-block {
    width: 100%;
    margin-top: 4em;
  }
}

.service-block {
  padding: 0.75em 1.33em;
  border: 1px solid $grayLighter;
  margin-bottom: 1em;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  flex-flow: row nowrap;
  display: flex;

  small {
    color: $gray;
    display: block;
  }

  span {
    color: $grayDark;
  }
}

.service-icon {
  width: 4em;
  margin-right: 1.33em;
  padding: 0.5em 1.67em 0.5em 0.33em;
  border-right: 1px solid $grayLighter;
}

.service-description {
  width: 100%;
  font-size: 1.15em;
}

.service-actions {
  width: 28em;
  display: inline-flex;
  justify-content: space-between;
}

.contacts-table {
  display: block;
  overflow-x: auto;

  thead tr th.header {
    padding: 15px 0px;
    text-align: center;
    font-weight: 600;
  }
  thead,
  tbody {
    width: 100%;
    p {
      border-radius: 27px;
      color: #ffffff;
      text-transform: capitalize;
      padding: 0px 9px;
    }
    p.invalid {
      background: #e57373;
    }
    p.valid {
      background: #81c784;
    }
    p.warning {
      background: #ffb74d;
    }
  }

  td[colspan] {
    border-left: none;
    border-right: none;
    border-bottom: none;
    text-align: center;

    * {
      font-style: italic;
    }
  }

  td:first-of-type {
    width: 2em;
  }
}
