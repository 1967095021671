@use '../../shared/Button.scss';
@import './variables';
@import './variables';

/*-----------  Team Settings  -----------*/

#sites-team {

  .button {
    @extend .green-button !optional;
  }

  .name-tag {
    display: inline-block;
    vertical-align: middle;

    .name {
      font-weight: $fontWeightBold;
    }
  }

  .new_site_membership {
    &.ready {
      .email_input {
        display: inherit;
      }
    }

    .email_input {
      display: none;
    }
  }

  .user-avatar {
    width: 2.5em;
    height: 2.5em;
    margin: 0 0.75em;
    border-radius: 10em;
    border: 2px solid $white;
    vertical-align: middle;
    display: inline-block;
  }

  .permission-icon {
    width: 1.45em;
    height: 1.45em;
    border-radius: 0.75em;
    display: inline-block;
    margin-right: 1em;
    vertical-align: middle;

    &.owner {
      background-color: $red;
    }

    &.admin {
      background-color: #27AB9E;
    }

    &.viewer {
      background-color: #0288d1;
    }

    &.none {
      background-color: $gray;
    }
  }

  .dropdown-block {
    .permission-icon {
      width: 0.95em;
      height: 0.95em;
      border-radius: 0.5em;
    }
  }

  .table-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: visible;
    white-space: nowrap;
    padding-bottom: 115px;
  }

  .team-table {
    border-collapse: collapse;
    overflow-y: visible;
    position: relative;
  }

  .permission-block {
    position: relative;
    top: -125px;
    margin-top: 1em;
    font-size: 0.9em;

    .permission-icon {
      width: 1.25em;
      height: 1.25em;
    }

    strong {
      margin-right: 0.3em;
    }
  }
}
