@import './mixins';
@import './variables';

/*-----------  Page Header  -----------*/

.page-header {
  color: $red;
  position: relative;
  margin-bottom: 2em;
  padding-bottom: 0.5em;
  padding-left: 0 !important;
  text-align: left !important;
  border-bottom: 2px solid $red;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.5rem;
    @include inline-block(baseline);
  }

  span {
    @include inline-block(bottom);
    @include ellipsis();
  }

  .dropdown-wrapper {
    .dropdown-block {
      border-color: $red;
    }
  }

  &.alt {
    border-color: $grayLighter;

    * {
      color: $grayLighter;
    }

    .dropdown-wrapper {
      .dropdown-block {
        border-color: $grayLighter;
      }
    }
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spacer {
  margin-bottom: 2em;
}

.margin-10 {
  margin: 10px;
}

.inline-div {
  display: inline-flex;

  &.baseline {
    place-items: baseline;
  }

  :last-child {
    margin-right: 0;
  }
}

.go-back-link {
  display: inline-flex;
  color: rgba(26, 26, 26, 0.5);
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.Flex {
  display: -ms-flexbox;
  display: flex;
}

.Flex-direction--row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.MuiPaper-root {
  padding: 1em;
}

.MuiDialogActions-root {
  span {
    flex-grow: 1;
  }
}

.modal {
  position: fixed;
  overflow-y: hidden;
  background: #ffffff;
  z-index: 1000;
  &.site-coupon {
    right: 10px;
    height: unset;
    width: unset;
    left: unset;
    border: 1px solid rgba($orange, 0.6);
    border-radius: 8px;

    .countdown {
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      align-items: center;
      margin-bottom: 5px;
      div {
        display: inline-block;
      }
      span {
        display: inline-block;
        border-radius: 10px;
        margin: 5px;
        background-color: #3d404e;
        color: #ffffff;
        padding: 10px;
        margin-top: 10px;
      }
    }
  }

  &-content {
    width: 315px;
    padding: 12px 0px;
    box-sizing: border-box;
    position: relative;
    pointer-events: auto;
    text-align: center;

    h3 {
      font-size: 18px;
      color: $grayDarker;
    }
    h4 {
      padding-top: 10px;
      font-size: 12px;
      color: $grayDarker;
    }
  }
  &-button-group {
    text-align: center;
    .button {
      display: center;
      border-color: $orangeLight;
      background-color: $orangeLight !important;
    }
  }
}

.form-row {
  margin-bottom: 16px;
  position: relative;
  .input-holder {
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
  textarea {
    height: 125px;
    width: 100%;
    z-index: 111;
    border: 1px solid $grayLight;
    border-radius: 4px;
  }
}
.form-row-action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px 5px;
  margin-top: -10px;
}
// paywall local

.want-to-chat {
  .paywall-lock {
    bottom: 20px;
    left: 40px;
    top: unset;
    right: unset;
    &-icon {
      position: relative;
      width: 109px;
      height: 33px;
    }
  }
}
.EmailEnableInput,
.EmailValidationEnableInput {
  .email_enable_input,
  .validate_email_input {
    .paywall-lock {
      top: initial;
      padding: 10px;
    }
  }
}

.EmailValidationEnableInput {
  padding-bottom: 20px;
  display: inline-block;
}

.paywall-lock {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 123;

  &-icon {
    position: relative;
    width: 24px;
    height: 24px;
    background: url('../images/icon-lock.svg') no-repeat center center;
    cursor: pointer;
  }
}

.icon-support-position {
  position: relative;
  top: 3px;
}

.email_input_message {
  left: 6px;
}

.dropdown-label {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  background-color: 'black';
  color: 'white';
  padding: 8px;
  &-title {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
    font-family: 'Roboto';
  }
  &-video {
    width: 230px;
    height: 200px;
    margin-bottom: 8px;
  }
  &-message {
    font-size: 12px;
    margin-bottom: 8px;
    font-family: 'Roboto';
  }
  &-read-more {
    border: 1px solid #ffffff;
    border-radius: 4px;
    background-color: #7a7a7a69;
    font-size: 12px;
    font-family: 'Roboto';
    &:hover {
      background-color: transparent;
      top: -2px;
    }
  }
}

.line {
  display: block;
  margin: 0px 0px 20px;
  h2 {
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid lightgray;
    position: relative;
    span {
      background-color: #ffffff;
      position: relative;
      top: 7px;
      color: lightgray;
      padding: 0 10px;
    }
  }
}
