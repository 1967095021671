.email-input {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 10px;
  border: 1px solid #d6d8da;
  border-radius: 6px;
  width: 100%;

  select {
    flex: 1;
    border: none;
  }

  .email_users {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .email_user {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 6px 8px;
    margin-right: 5px;
    list-style: none;
    border-radius: 6px;
    background: #e8562a;
  }
  
  .email_user_title {
    margin-top: 3px;
    color: #FFFFFF;
  }

  .email_close_icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #e8562a;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}
