@import './variables';
@import './mixins';

table.rules-wrapper {
  width: 100%;
  max-width: 100%;
  border-spacing: 0 0.75em;
  border-collapse: collapse;

  .head-right {
    display: inline-flex;
    right: 0;
    position: absolute;
    padding-top: 12px;
    padding-right: 10px;
  }

  tr {
    height: 4rem;

    &.spacer {
      height: 1rem;
    }

    &:first-child {
      height: auto;
    }

    th, td {
      font-size: 0.9em;
      text-align: center;
      margin-bottom: 0.67em;

      &.site-element__headline {
        cursor: pointer;
        text-align: left;
        padding-left: 1.33rem;
      }
    }

    /*-----------  Table Headers  -----------*/

    th {
      padding: 1.67em 0 0.67em;
    }

    th:not([colspan]) {
      font-size: 0.75em;
      color: $gray;
      padding-top: 0 !important;
      text-transform: uppercase;
      border-bottom: 1px solid $grayLighter;
    }

    th[colspan] {
      .dropdown-wrapper {
        right: -0.75em;
        bottom: 0.33em;
        position: absolute;

        > i {
          font-size: 1.15em;
        }
      }
    }

    /*-----------  Rule Row ----------*/

    &.rule-block {

      .rule-description {
        max-width: 55em;
        @include ellipsis();
      }

      .remove-rule {

        span {
          margin: 0;

          &:before, &:after {
            content: " ";
          }
        }
      }
    }

    /*-----------  Site Element Rows  -----------*/

    &.site-element-block {
      border-color: $grayLighter;
      transition: all 0.15s ease-out 0s;

      &.winner {
        font-size: 1.3em;
      }

      &.looser {
        font-size: 0.95em;
      }

      td {
        padding: 0;
        min-width: 5.5rem;
        @include antialiased();

        > i {
          color: $grayLight;
          font-size: 2.25em;
          vertical-align: bottom;
        }

        &.site-element__headline {
          width: 100%;
          max-width: 12em;
          padding-left: 1.67rem;

          p {
            width: 100%;
          }
          .updated_by_details {
            font-size: 10px;
          }
        }

        &.site-element__age {
          min-width: 6rem;
        }

        &.site-element__type {
          background-color: $blueLighter;
          vertical-align: bottom;

          [class*='icon-'] {
            color: $red;
          }
        }

        &.site-element__convertions-percent {
          min-width: 6rem;
          font-weight: $fontWeightBold;
        }

        &.site-element__actions {
          min-width: 7.5rem;
          border-right-width: 1px;
          background-color: $grayLightest;

          .icons-graph {
            margin-right: 13px;
            cursor: pointer;
          }

          .icons-graph,
          .testing-icon,
          .dropdown-wrapper > i {
            top: 3px;
            color: $grayLight;
            vertical-align: middle;
          }

          .testing-icon {
            border-radius: 50%;
            width: 1.2em;
            height: 1.2em;
            color: white;
            font-style: initial;
            font-size: 1.2em;
            display: inline-block;
          }

          .testing-icon {
            .numbers {
              left: 0;
              right: 0;
              font-weight: $fontWeightBold;
            }

            &.icon-tip {

              .numbers {
                top: 16%;
                font-size: 0.55em;
              }
            }
          }

          .dropdown-wrapper.adjusted {
            margin-right: -1.33rem;

            > i {
              font-size: 1.33em;
              padding-left: 1.25rem;
            }
          }

          .dropdown-wrapper {
            .dropdown-block {
              background-color: $white;
            }
            .dropdown-block.adjusted {
              right: -1.2em;
            }
          }
        }
      }

      /*-----------  Type Variations  -----------*/

      &.type-social {

        td.site-element__type [class*='icon-'] {
          color: $blue;
        }

        td.site-element__actions .testing-icon {
          background: $blue;
        }
      }

      &.type-traffic {

        td.site-element__type [class*='icon-'] {
          color: $yellow;
        }

        td.site-element__actions .testing-icon {
          background: $yellow;
        }
      }

      &.type-email {

        td.site-element__type [class*='icon-'] {
          color: $green;
        }

        td.site-element__actions .testing-icon {
          background: $green;
        }
      }

      &.type-announcement {

        td.site-element__type [class*='icon-'] {
          color: $purple;
        }

        td.site-element__actions .testing-icon {
          background: $purple;
        }
      }

      &.type-call {

        td.site-element__type [class*='icon-'] {
          color: $purple;
        }

        td.site-element__actions .testing-icon {
          background: $purple;
        }
      }


      /*-----------  Paused State  -----------*/

      &.paused {
        background-color: $grayLightest;

        td.color-flag {
          background-color: $grayLight !important;
        }

        td.site-element__headline p {
          color: $gray;
        }

        td.site-element__type {
          background-color: $grayLightest;
        }

        [class*='icon-'] {
          color: $grayLight !important;
        }
      }

      /*-----------  Paused State  -----------*/

      &.deactivated {
        span {
          color: $orange;
        }
        background-color: $redLightest;

        td.color-flag {
          background-color: $orangeLight !important;
        }

        td.site-element__headline p {
          color: $orange;
        }
        td.site-element__age {
          small {
            color: $orange;
          }
        }


        td.site-element__type {
          background-color: $redLightest;
        }
        td.site-element__actions {
          background-color: $redLightest;
        }

        [class*='icon-'] {
          color: $orangeLight !important;
        }
      }

      /*-----------  Deleting State  -----------*/

      &.deleting {
        animation: barberpole 5s linear infinite;
        @include striped-bg(lighten($grayLightest, 5%));

        td {
          opacity: 0.33;
          background-color: transparent;

          [class*='icon-'] {
            color: $grayLight !important;
          }
        }

        .dropdown-wrapper {
          pointer-events: none;
        }
      }
    }

    /*-----------  Paused / Active Counters  -----------*/

    &.elements-paused {
      background-color: $grayLightest;

      td {
        color: $gray;
        font-size: 0.8em;
        padding: 0.5em 1.15em;
        @include antialiased();

        a {
          color: $blue;
          margin-left: 0.5em;
          text-decoration: underline;

          &:hover {
            color: $blueLight;
          }
        }
      }
    }

    /*-----------  Suggestion Rows  -----------*/

    &.suggestion-block {
      padding: 1.15em;
      border: 1px dashed;
      // border-left-style: dashed;
      // border-right-style: dashed;

      td {
        border-bottom: 1px dashed $inputBorderColor;
      }
      td > a {
        color: #3cd4d9;
        text-decoration: underline;
      }

      td:last-of-type {
        border-right: 1px dashed $inputBorderColor;
        border-left: 1px dashed $inputBorderColor;
      }

      &.icon-wrapper {
        float: left;

        i {
          font-size: 2em;
          color: $yellow;
          vertical-align: bottom;
        }
      }
    }
  }

  /*-----------  Active / Paused Filters  -----------*/

  .is-active {

    .site-element-block.paused {
      display: none;
    }

    .elements-paused.displayed {
      display: table-row;
    }

    tr.paused {
      display: none;
    }
  }

  .is-paused {

    .site-element-block.active {
      display: none;
    }

    tr.active {
      display: none;
    }
  }

  .is-all {

    tr.elements-paused {
      display: none;
    }
  }
}

table .NewRuleButton {
  tr td {
    padding-top: 2em;
    border: none;
  }
}
