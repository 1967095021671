/*-----------  Icon Mixin  -----------*/
/*-----------  Custom Icon Font  -----------*/
@font-face {
  font-family: "hellobar";
  src: url(../fonts/hellobar.eot);
  src: url(../fonts/hellobar.eot?#iefix) format("embedded-opentype"), url(../fonts/hellobar.woff) format("woff"), url(../fonts/hellobar.ttf) format("truetype"), url(../fonts/hellobar.svg#hellobar) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hellobar-new";
  src: url(../fonts/hellobar-new.eot);
  src: url(../fonts/hellobar-new.eot?#iefix) format("embedded-opentype"), url(../fonts/hellobar-new.woff) format("woff"), url(../fonts/hellobar-new.ttf) format("truetype"), url(../fonts/hellobar-new.svg#hellobar) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hellobar-icons";
  src: url(../fonts/hellobar-icons.eot);
  src: url(../fonts/hellobar-icons.eot?#iefix) format("embedded-opentype"), url(../fonts/hellobar-icons.woff) format("woff"), url(../fonts/hellobar-icons.ttf) format("truetype"), url(../fonts/hellobar-icons.svg#hellobar-icons) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hellobar-icons";
  src: url(../fonts/hellobar-icons.svg#hellobar-icons) format("svg");
}

[data-icon]:before {
  font-family: 'hellobar' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before, [class*=' icon-']:before {
  font-family: 'hellobar' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='hb-']:before, [class*=' hb-']:before {
  font-family: 'hellobar-new';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-abtest:before { content: "a";}

.icon-dropdown:before { content: "b"; }

.icon-noload:before { content: "c"; }

.icon-views:before { content: "d"; }

.icon-wordpress:before { content: "e"; }

.icon-notification:before { content: "f"; }

.icon-edit:before { content: "g"; }

.icon-back1:before { content: "h"; }

.icon-back2:before { content: "i"; }

.icon-export:before { content: "j"; }

.icon-opportunity:before { content: "k"; }

.icon-notification-alert:before { content: "l"; }

.icon-eyedropper:before { content: "m"; }

.icon-badge:before { content: "n"; }

.icon-bar:before { content: "o"; }

.icon-feedback:before { content: "p"; }

.icon-play:before { content: "q"; }

.icon-settings:before { content: "r"; }

.icon-forwardarrow:before { content: "s"; }

.icon-calendar:before { content: "t"; }

.icon-clicks:before { content: "u"; }

.icon-gear:before { content: "v"; }

.icon-social:before { content: "w"; }

.icon-style:before { content: "x"; }

.icon-close:before { content: "z"; }

.icon-color:before { content: "A"; }

.icon-installmyself:before { content: "B"; }

.icon-summary:before { content: "C"; }

.icon-support:before { content: "D"; }

.icon-contacts:before { content: "E"; }

.icon-content:before { content: "F"; }

.icon-magnifyingglass:before { content: "G"; }

.icon-manage:before { content: "H"; }

.icon-sync:before { content: "I"; }

.icon-tip:before { content: "J"; }

.icon-trash:before { content: "K"; }

.icon-modal,.icon-takeover,.icon-slider {
  &:before {
    content: "L";
  }
}

.icon-mobile:before { content: "M"; }

.icon-desktop:before { content: "N"; }

.icon-download:before { content: "O"; }

.icon-target:before { content: "y"; }

.icon-thumbsup:before { content: "P"; }

.icon-pants:before { content: "Q"; }

.icon-unbranded:before { content: "R"; }

.icon-unlimited:before { content: "S"; }

.icon-pointer:before { content: "T"; }

.icon-rocket:before { content: "U"; }

.icon-growthbars:before { content: "V"; }

.icon-circle:before { content: "W"; }

.icon-prize-award:before { content: "X"; }

.icon-twitter-circled:before { content: "Y"; }

.icon-pinterest-circled:before { content: "Z"; }

.icon-linkedin-circled:before { content: "0"; }

.icon-gplus-circled:before { content: "1"; }

.icon-facebook-circled:before { content: "2"; }

.icon-docs:before { content: "3"; }

.icon-pause:before { content: "4"; }

.icon-buffer-circled:before { content: "5"; }

.icon-money:before { content: "6"; }

.icon-other:before { content: "7"; }

.icon-alert:before { content: "8"; }

.icon-megaphone:before { content: "9"; }

.icon-call:before { content: "\""; font-weight: bold !important; }

.hb-instagram-circled:before { content: "["; }

.hb-youtube-circled:before { content: "]"; }

.icon-wix:before { content: "{"; }

.icon-clipboard:before { content: "|"; }
