.new-site {
  width: 45%;
  padding: 2.75em;
  margin: 3.5em auto 4em;
  border-radius: 5px;
  background-color: #f6f6f6;
  text-align: center;

  .url-wrapper {
    text-align: left;
    input {
      width: 95%;
    }
  }

  .select-wrapper {
    text-align: left;
    background-color: transparent;

    select {
      width: 100%;
    }
    &:before {
      right: 10px;
    }
  }
}
