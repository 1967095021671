@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: 'Geomanist-Medium';
  src: url("../fonts/Geomanist-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Geomanist-Medium.woff2") format("woff2"), url("../fonts/Geomanist-Medium.woff") format("woff"), url("../fonts/Geomanist-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Geomanist-Regular';
  src: url("../fonts/Geomanist-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Geomanist-Regular.woff2") format("woff2"), url("../fonts/Geomanist-Regular.woff") format("woff"), url("../fonts/Geomanist-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

.signup-container {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-image: url(//assets.hellobar.com/signup/desktop-bg-v2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5%;
}

@media screen and (max-width: 1280px) {
  .signup-container {
    padding: 0 2%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1025px) {
  .signup-form {
    padding-right: 32px !important;
    padding-left: 32px !important;
    max-width: 420px !important;

    .signup-strike {
      span:before, span:after{
        width: 172px !important;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .signup-container {
    display: block;
    overflow-y: scroll;
    background-image: url(//assets.hellobar.com/signup/tablet-bg-v2.png);
    padding: 0;

    .popup-inner{
      margin: 122px auto 0;
      flex-direction: column;

      .popup-slider-signup {
        margin: 32px auto 66px !important;
        border-radius: 5px !important;
        max-width: 100% !important;

        .inner-slider-container {
          margin: auto;
          max-width: 658px;
          width: 100%;
        }
      }
    }
  }

  .signin-container {
    .auth-sidebar {
      display: none !important;
    }
    .auth-main-logo {
      display: block !important;
      margin-bottom: 0px !important;
      margin-top: 3em;
    }
  }
}

@media screen and (max-width: 765px) {
  .signup-container {
    .popup-inner {
      flex-direction: column;
      margin-top: 0px;

      .signup-form {
        max-width: 100%;
        padding: 78px 17px 48px;
        border-radius: 0px;

        .signup-strike {
          span:before, span:after{
            width: 9999px;
          }
        }
      }

      .popup-slider-signup {
        margin-bottom: 0px !important;
        padding-left: 16px;
        .inner-slider-container {
          max-width: 100%;
        }
      }
    }
  }
}

.signup-container, .signin-container {
  .popup-inner {
    max-width: 100%;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  
  .strike {
    display: block;
    overflow: hidden;
    margin: 1em 0;
    text-align: center;
    white-space: nowrap;

    span {
      position: relative;
      text-align: center;
      white-space: nowrap;
      font-family: 'Geomanist-Regular';
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #636363;
      display: block;
      margin: 10px auto 10px;
      background-color: #fff;
      position: relative;
      z-index: 10;
      width: 42px;
    }

    span:before, span:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: #4f4f4f;
    }

    span:before {
      right: 100%;
      margin-right: 15px;
    }

    span:after {
      left: 100%;
      margin-left: 15px
    }
  }

  .popup-form {
    text-align: center;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 33px 48px 47px;
    max-width: 468px;
    width: 100%;

    h4, h3 {
      font-family: 'Geomanist-Medium';
      font-style: normal;
      text-transform: capitalize;
      margin-bottom: 0.67em;
      font-style: normal;
      font-weight: 500;
    }

    h4 {
      font-size: 21px;
      margin-bottom: 1em;
    }

    .MuiButton-root {
      width: 100%;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 16px;
      height: 3em;
    }

    .margin-top {
      margin-top: 1em;
    }

    button.google-button {
      display: flex;
      width: 100%;
      margin-top: 0px;
      padding: 0px;
      -webkit-justify-content: start;
      justify-content: flex-start;
    }

    .google-button svg {
      width: 20px;
      height: 18px;
      fill: white;
    }

    .google-button span.icon {
      padding: 10px 16px 8px;
      border-right: 1px solid white;
    }

    .google-button span.button-text {
      flex: 1;
      align-self: center;
      text-align: center;
    }

    .MuiTextField-root {
      width: 100%;
      margin-bottom: 1.5em;
    }

    .terms-policy {
      font-family: 'Geomanist-Regular';
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #494949;
      margin: 0;

      a {
        color: #025FAA;
      }
    }

    .links-container {
      font-family: 'Geomanist-Regular';
      margin: 1em 0 0;
      text-align: center;
      font-size: 0.8em;

      a {
        font-family: 'Geomanist-Regular';
        font-weight: normal;
        color: #e8562a;
        text-decoration: none;
        display: inline-block;
        margin: 0.3em 0;
        font-size: 1.2em;
      }

      .bold-link {
        font-family: 'Geomanist-Medium';
        text-decoration: underline;
      }
    }

    .signup-strike {
      overflow: visible;
      margin: 1.5em 0;

      span:before, span:after{
        background: rgba(0, 0, 0, 0.12);
        width: 198px ;
      }
    }
  }

  .popup-slider {
    max-width: 711px;
    width: 100%;
    background: rgb(143, 143, 143);
    border-radius: 0 5px 5px 0;
    padding: 22px 15px 20px 36px;
    background: rgba(255,255,255,0.24);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);

    .MuiPaper-root {
      background-color: transparent;
    }

    div.info-wrap {
      display: flex;

      img {
        max-width: 105px;
        width: 100%;
        height: 105px;
        margin: 0;
      }

      div.info-content {
        margin-left: 25px;

        h2 {
          font-family: 'Geomanist-Medium';
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 120%;
          color: #FFFFFF;
          text-transform: none;
          letter-spacing: 0.5px;
          margin-bottom: 10px;
        }

        h4 {
          font-family: 'Geomanist-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;
          color: #FFFFFF;
          text-transform: none;
          letter-spacing: 0.1px;
        }
      }
    }

    .review-wrap {
      position: relative;
      max-width: 660px;
      width: 100%;
      background: #6E90A7;
      border-radius: 10px;
      padding: 21px 14px 25px 33px;
      margin: 16px auto 0px;

      :before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 19px solid #6E90A7;
        top: -15px;
        left: 37px;
        z-index: -1;
      }

      p {
        font-family: 'Geomanist-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        color: #FFFFFF;
        margin-bottom: 0;
        letter-spacing: 0.1px;
      }
    }

    .MuiButtonBase-root:hover {
      color: #FFFFFF;
      background-color: #FFFFFF;
    }
  }
}

.signin-container {
  display: flex;
  width: 100vw;
  min-height: 100vh;

  .auth-sidebar {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: calc(44vw - 100px);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 40px;
    padding-bottom: 0px;
    position: relative;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffcc66), to(#e04718));
    background-image: linear-gradient(to bottom, #ffcc66, #e04718);
    color: white;
    overflow: hidden;

    .sidebar-image {
      img {
        width: 100%;
        min-height: auto;
      }
    }

    .popup-slider {
      background-color: transparent;
      padding: 0px 30px 20px 30px
    }
  }

  .auth-main {
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    display: -webkit-box;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;

    .popup-form {
      margin: auto;
    }
  }

  .auth-main-logo {
    display: none;
  }

  .logo {
    width: 15em;
    height: 3.33em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../images/hellobar-logo.png');
    cursor: pointer;
  }
}
