.event-input {
  * {
    width: 100%;
  }

  .code {
    margin-top: 15px;

    label {
      margin-bottom: 10px;
    }
  }
}

.identity-section {
  margin-top: 20px;
  h5 {
    padding-bottom: 8px;
    border-bottom: 1px solid #E8562A
  }
}

.font-section {
  margin-top: 20px;

  &-header {
    display: flex;
    align-items: baseline;
    position: relative;
    font-size: 1.5rem;
    line-height: 1em;
    font-weight: 600;
    color: darkgray;
    margin-bottom: 20px;

    &:after {
      content: '';
      flex: 1 1 0px;
      margin-left: 20px;
      height: 1px;
      background: #ccaddd;
      opacity: 0.5;
    }
  }
}

.font-table{
  margin-top: 0px;
}
