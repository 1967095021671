@use "sass:math";

/*----------- Colors -----------*/

$white            : rgb(255, 255, 255);

$grayLightest     : rgb(246, 246, 246);
$grayLighter      : rgb(226, 226, 226);
$grayLight        : rgb(198, 198, 198);
$gray             : lighten(rgb(132, 132, 132), 5%);
$grayDark         : rgb(82, 82, 82);
$grayDarker       : rgb( 79, 79, 79);

$black            : $grayDarker;

$blueLighter      : rgb(238, 247, 253);
$blueLight        : rgb( 60, 212, 217);
$blue             : rgb( 69, 180, 214);

$turtleGreen      : rgb( 39, 171, 158);

$green            : rgb( 84, 211, 136);
$greenDark        : rgb( 0, 181, 117);
$greenShadow      : rgb( 0, 148, 94);

$purple           : rgb(157, 83, 188);

$info-yellow      : rgb(255, 255, 213);
$yellow           : rgb(255, 191, 31);
$alert-yellow     : rgb(254, 245, 224);
$dark-yellow      : rgb(116, 91, 50);

$orangeLightest   : rgb(244, 171, 149);
$orangeLight      : rgb(236, 116, 80);
$orange           : rgb(235, 89, 60);

$red              : rgb(232, 86, 42);
$redLightest      : rgb(247, 222, 225);

$inputBorderColor : $grayLighter;
/*----------- Social Colors -----------*/

$twitter          : rgb( 0, 172, 237);
$facebook         : rgb( 59, 89, 152);
$linkedin         : rgb( 0, 123, 182);
$pinterest        : rgb(203, 32, 39);
$google           : rgb(221, 75, 57);
$youtube          : rgb(195, 39, 26);
$instagram        : rgb(249, 112, 37);
$buffer           : $black;

/*----------- Sizing -----------*/

$emBase           : 15px;

$maxWidth         : 78em;
$minWidth         : 68em;

$smallWidth       : 32em;
$blockWidth       : 52em;

$sidebarWidth     : 14em;
$headerHeight     : 60px; //4.25rem

$gutter           : 2em;
$gutterSm         : math.div($gutter, 1.75);
$gutterLg         : $gutter * 1.75;

$blockPadding     : 0.75em 1.15em;

/*----------- Styling Defaults -----------*/

$radiusSm         : 3px;
$radiusLg         : 5px;

$border           : 1px solid $gray;
$borderLight      : 1px solid $grayLight;
$borderLighter    : 1px solid $grayLighter;

$shadow           : 0.33em 0.33em 1em rgba($grayDarker, 0.15);
$shadowSubtle     : 0.33em 0.33em 1em rgba($grayDarker, 0.08);

$modalSpeed       : 0.33s;

/*----------- Fonts -----------*/

$fontFamily       : 'Roboto', sans-serif;
$fontWeightLight  : 400;
$fontWeightNormal : 600;
$fontWeightBold   : 700;

$textPrimary: #1a1a1a;
$textSecondary: rgba(26,26,26,.5);
