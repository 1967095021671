@use '../../../shared/Button.scss';

.site-privacy__subhead {
  font-size: 1em;
  font-weight: bold;
  color: inherit;
  text-transform: none;
  margin: 2em 0 1em 0;
}

.site_communication_types {
  input[type=checkbox] {
    width: auto;
    margin-right: 0.5em;
  }

  span.checkbox label:hover {
    cursor: pointer;
  }
}

.site-privacy__column {
  width: 33%;
}

.site-privacy__actions {
  margin-top: 2em;
}

form.site-privacy {
  .button {
    @extend .green-button !optional;
    width: 10em;
  }
}
