@import "variables";

.MuiPaper-elevation4 {
  border-bottom: 2px solid #c6c6c6 !important;
  box-shadow: none !important;
  background: white !important;
}

.MuiAppBar-colorDefault {
  background-color: white !important;
}

.MuiTab-root {
  font-size: 1.15em !important;
  min-width: 100px !important;
  color: #c6c6c6;
}

.MuiBox-root-7 {
  padding: 8px !important;
}

.MuiTabs-root {
  margin-bottom: -2px!important;
}

.MuiDialog-container .MuiDialogTitle-root+.MuiDialogContent-root {
  padding-top: 1em;
}

.MuiDialogContent-root {
  color: $grayDark;
  font-size: 0.95em;
  padding-top: 1em;
}

.MuiChip-root.MuiChip-clickable.MuiChip-outlinedSecondary:hover {
  color: white;
  background-color: #27AB9E;
}
