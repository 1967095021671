@use '../../shared/Button.scss';
@import './mixins';
@import './variables';

/*-----------  User Profile  -----------*/

#user-edit,
#user-update,
#user-destroy {
  .profile-wrapper {
    .column {
      width: 21.75%;
      margin-right: 4%;
      @include inline-block(top);
    }
  }

  /*-----------  Profile Forms  -----------*/

  .hidden {
    display: none;
  }

  .personal-form {
    display: inline;
  }

  .personal-form .button {
    @extend .green-button !optional;
  }

  .password-form .button {
    @extend .gray-button !optional;
  }

  form {

    a:not(.button) {
      color: $blue;
      display: block;
      font-size: 0.85em;
      margin-top: 1.25em;
    }
  }

  /*-----------  User Avatar  -----------*/

  form.avatar-form {
    padding: 1.5em;
    text-align: center;
    border-radius: $radiusSm;
    border: 1px solid $grayLighter;

    .button {
      @extend .gray-inverted-button !optional;
    }

    a:not(.button) {
      color: $blue;
      display: block;
      font-size: 0.85em;
      margin-top: 0.75em;
    }
  }
}

.inputs {
  .TimezoneInput {
  }
}
