@import '../mixins';
@import '../variables';

/*-----------  Edit Site  -----------*/

#sites-edit,
#sites-update {

  .hidden {
    display: none;
  }

  .inline {
    display: inline-flex !important;
  }

  footer {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $inputBorderColor;
  }

  .edit_site {
    form a {
      margin-top: 0;
    }

    .add-site-wrapper {
      margin-top: 30px;
      text-decoration: underline;

      a {
        color: #27AB9E;
      }
    }

    .add-card-wrapper {
      text-align: left;
      margin-top: 30px;

      span.expired {
        text-decoration: line-through;
      }

      .expired-tag {
        padding-left: 10px;
        color: #e8562a;
      }

      a {
        color: #27AB9E;
        text-decoration: underline;
      }
    }

    .url-wrapper,
    .selector-wrapper,
    .button-wrapper {
      width: 30%;
      @include inline-block();

      &.large {
        width: 70%;
      }

      label {
        margin-top: 0;
      }
    }

    .select-wrapper {
      width: 30%;
      @include inline-block();

      &.large {
        width: 70%;
      }

      label  {
        margin-top: 0;
      }
    }


    .url-wrapper,
    .selector-wrapper {
      margin-right: 2.5%;

      label:last-child {
        font-size: 0.85em;
      }
    }

    .selector-wrapper {
      input {
        margin: 0 !important;
      }
    }

    .button-wrapper {
      vertical-align: top !important;
    }
  }

  .button-wrapper {
    vertical-align: super !important;

    a {
      color: $blue;
      display: block;
      font-size: 0.85em;
      text-decoration: underline;
    }
  }

  .button-wrapper.promo-label {
    border: 1px solid $blue;
    padding: 10px;
  }

  .button-wrapper.promo-button {

    a {
      background-color: $blue;
      color: $white;
      padding: 10px;
      text-decoration: none;
      width: auto;
      white-space: nowrap;
      font-size: 15px;
    }
  }

  /*-----------  Billing Section  -----------*/

  .billing-wrapper {
    margin-top: 3.5em;

    section {
      margin-bottom: 2em;

      th {
        color: #9B9B9B;
        font-size: 14px;
        font-weight: 500;
      }

      label {
        width: 11%;
        float: left;
        margin-top: 0;
        min-width: 5em;
        line-height: 155%;
      }

      > p {
        color: $gray;
        padding-left: 10%;

        #site_invoice_information {
          width: 50%;
        }

        a:not(.button) {
          color: $blue;
          margin-left: 0.67em;
          text-decoration: underline;
        }

        a.no-left-margin {
          margin-left: 0px;
        }

        .button {
          font-size: 0.85em;
          margin-left: 0.5em;
        }
      }

      table {
        clear: both;
        margin-left: 0;
        margin-bottom: 0.5em;

        th {
          padding: 0.85em 1em;
          font-weight: bold;
        }

        td {
          padding: 0.85em 1em;

          img {
            width: 15px;
          }
        }

        tbody tr:nth-child(even) {
          background-color: $grayLightest;
        }
      }

      small {
        font-size: 0.9em;
        color: $grayLight;
        @include antialiased();
      }
    }

    .half-block {
      width: 98%;
      padding-right: 7%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      margin-bottom: 3.5em;

      & + .half-block {
        padding-right: 0;
        text-align: center;
      }

      table {
        width: 100%;
      }
    }

    .free_block {
      max-height: 240px;
      border-radius: 2px;
      background-color: #F6F6F6;

      .padding {
        padding: 2.5em;

        .inline {
          display: inline-flex;
          padding-bottom: 20px;
        }
        .underline {
          text-decoration: underline;
        }
      }
    }

    h6 {
      text-align: center;
      &.align-l {
        text-align: left;
      }

    }

    .upgrade-table {
      margin-right: -5%;
      margin-top: $gutterSm;

      li {
        width: 45%;
        color: $gray;
        float: left;
        text-align: left;
        margin-right: 5%;
        font-size: 0.85em;
        position: relative;
        padding: $gutterSm $gutter;
      }

      i {
        left: -0.5em;
        position: absolute;
        font-size: 1.2em;
        color: $green;
      }
    }
  }

  .floating-button {
    left: 75%;
    position: absolute;
    transform: translateX(-50%);
  }
}

.download-invoice-icon {
  width: 15px;
}

.free_block {
  max-height: 240px;
  border-radius: 2px;
  background-color: #F6F6F6;

  .padding {
    padding: 2.5em;

    .inline {
      display: inline-flex;
      padding-bottom: 20px;
    }
    .underline {
      text-decoration: underline;
    }
  }
}
