@import '_variables.scss';

.ProductSummary {
  margin-top: 20px;
}

.OrderDetails {
  margin-top: 32px;
  margin-bottom: 32px;

  .OrderDetailsItem {
    margin-bottom: 16px;
  }

  &-total {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(26,26,26,.1);
    border-top: 1px solid rgba(26,26,26,.1);
    margin-bottom: 16px;
  }

  &Footer-subtotal {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(26,26,26,.1);
    margin-bottom: 16px;
  }

  &Footer-subtotal-items {
  }

  &Footer-total {
    padding-bottom: 16px;
  }

  .OrderDetailsSubtotalItem {
    margin-bottom: 16px;
  }

  .PromotionCodeEntry {
    margin-bottom: 16px;
    font-size: 14px;
    width: 90%;
  }

  .PromotionCodeEntry-Chip {
    label {
      color: $gray;
      margin-left: 13px;
      margin-top: 5px;
      font-weight: normal;
    }
  }

  .PromotionCodeEntry-Error {
    color: $red;
    margin-left: 0;
    margin-top: 10px;
    font-weight: normal;
  }

  .PromotionCodeEntry-label {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    white-space: nowrap;
    height: 0;
    visibility: hidden;
    transition-property: padding;
  }

  .PromotionCodeEntry-inputWrapper {
    &--blurred {
      min-width: 15%;
      transition-property: min-width;

      .CheckoutInput {
        color: $textPrimary;
        &::placeholder {
          color: $textPrimary;
        }
      }
    }

    &--focused {
      .CheckoutInput {
        color: $textSecondary;
        &::placeholder {
          color: $textSecondary;
        }
      }
    }

    margin: 0;
    position: relative;
    display: inline-block;
    min-width: 100%;
  }

  .InputContainer {
    transition-timing-function: ease-out;
    transition-duration: .2s;
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  .CheckoutInput {
    color: $textPrimary;
    background-color: rgba(26,26,26,.05);
    box-shadow: none;
    padding: 8px 12px;
    cursor: pointer;
    line-height: 1.3;
    border-radius: 6px;
    transition-property: padding,line-height,background,box-shadow;
    font-size: 14px;
    width: 100%;
    margin: 0;

    &:focus {
      box-shadow: 0 1px 1px 0 rgb(0, 0, 0), 0 0 0 4px rgb(0, 0, 0);
      box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05);
      background: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $textSecondary;
    }
  }
}
