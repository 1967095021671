.checklist-notification {
  position: fixed;
  padding: 10px;
  bottom: 0;
  z-index: 2147483647;
  line-height: 21px;
  max-width: 20%;
  right: auto;
  left: 0;
  float: none;

  .get-started {
    background-color: #387DFF;
    margin-top: 8px;
    border-radius: 40px;
  }
  &-inner {
    position: relative;
    background: #ffffff;
    text-align: right;
    width: 100%;
    min-width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    max-width: 400px;
    border-radius: 6px;
    box-shadow: 0 4px 19px -5px rgba(0, 0, 0, 0.43);
    border-radius: 6px;
    border: 0;
    .checklist-title {
      text-align: left;
      display: flex;
      justify-content: space-between;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      padding: 13px 21px;
      border-radius: 6px 6px 0 0;
      min-height: 40px;
      background: #387DFF;
      color: white;
      width: 100%;
      box-sizing: border-box;
      line-height: 20px;
    }
    .checklist-content {
      background: #387DFF;
      color: white;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 21px;
      display: flex;
      .checklist-progress {
        margin: 5px auto 40px auto;
        border-radius: 10px;
        height: 8px;
        display: flex;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.15);
        width: 100%;
        &-bar {
          background-color: #40c570;
        }
      }
      .checklist-progress-value {
        color: white;
        margin-right: 10px;
        white-space: nowrap;
      }
    }
    .checklist-actions {
      text-align: left;
      padding: 0 21px 13px 21px;
      border-radius: 0 0 6px 6px;
      float: none;
      margin-top: -34px;
      max-height: calc(100vh - 150px);
      overflow: auto;
      .checklist-button {
        background: #ffffff;
        margin-top: 8px;
        border-radius: 6px;
        font-size: 14px;
        font-family: inherit;
        line-height: 21px;
        box-sizing: border-box;
        margin-right: 0;
        display: inline-flex;
        position: relative;
        width: 100%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        padding: 13px;
        box-shadow: rgba(45, 62, 80, 0.12) 0 1px 5px 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          width: inherit;
        }
      }
      .svg-icon {
        margin-right: 8px;
        vertical-align: bottom;
        width: 24px;
        height: 24px;
      } 
      .done {
        color: rgba(0, 0, 0, 0.6);
        text-decoration: line-through;
        .svg-icon {
          fill: #387DFF;
        }
      }
      &:hover {
        text-decoration: none;
        color: #000000;
      }
    }
  }
}
