/*-----------  Upgrade Modal  -----------*/

@import url('https://rsms.me/inter/inter.css');
@import '../assets/styles/variables';

.plan-selection {
  padding: 50px;
  width: 1084px;
  text-align: center;
  margin: auto;
}

.free-plan-block {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  border: 1px solid $orangeLightest;
  display: inline-flex;
  align-items: center;
  justify-content: left;
  h5 {
    width: 20%;
    color: $orangeLightest;
    text-align: left;
    margin: 0em 1em 0em 0.33em;
    padding: 1em 0em;
    font-size: 18px;
    font-weight: bold;
    border-right: 1px solid $grayLighter;
  }
  .plan-price {
    width: 20%;
    padding: 0.33em 0em;
    border-right: 1px solid $grayLighter;
    .pricing {
      display: inline-flex;
      position: relative;
      align-items: baseline;
      justify-content: center;
      font-size: 36px;
      line-height: 1em;
      font-weight: bold;
      margin-top: 4px;
      margin-right: 10px;
      color: $black;

      &:after {
        content: attr(data-old-price);
        position: absolute;
        top: 4px;
        right: 0;
        text-decoration: line-through;
        font-size: 10px;
        line-height: 1em;
        letter-spacing: 0.04em;
        font-weight: normal;
        color: $gray;
      }
      .sign {
        align-self: flex-start;
        font-size: 20px;
        line-height: 1em;
        margin-right: 2px;
      }
      .amount {
        letter-spacing: -0.03em;
      }
      .period {
        font-size: 10px;
        line-height: 1em;
        letter-spacing: 0.04em;
        margin-left: 4px;
      }
    }
  }

  .plan-description {
    width: 30%;
    text-align: center;
    margin: 0em 1em;
    border-right: 1px solid $grayLighter;
    ul {
      list-style-type: none;
      li {
        margin: 0em;
        font-size: 12px;
      }
    }
  }

  .plan-action {
    width: 30%;
    .free-plan-button {
      padding: 10px 10px;
      height: 36px;
      border-radius: 4px;
      text-align: center;
      font-size: 0.85em;
      font-weight: bold;
      text-transform: uppercase;
      color: $orangeLightest;
      border: 1px solid rgba($red, 0.5);
      cursor: pointer !important;

      &:not(.popular):hover {
        border: 1px solid $orange;
        color: $orange;
      }
    }
  }
}

.upgrade-account-modal {
  color: #645f6a;
  top: 0;
  padding: 0;

  &.show-modal {
    background-color: rgba(249, 248, 244, 0.7);
    .modal-content {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
      overflow: hidden;
    }
  }
  .modal-content {
    position: relative;
    width: 800px;
    margin: 0 auto;
    padding: 0 48px;
    transform: translateY(120px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out, transform 0.3s ease;

    .icon-close {
      position: absolute;
      top: 20px;
      right: 25px;
      cursor: pointer;
      transition: all 0.15s ease-out 0s;
      &:hover {
        color: #eb593c;
      }
    }
  }
  .switch {
    padding-top: 10px;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;

    input {
      display: none;
    }
    .slider {
      border: 1px solid #e0e0e0;
      position: absolute;
      cursor: pointer;
      top: 8px;
      left: 0;
      right: 0;
      bottom: -8px;
      background-color: #ffffff;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      &:before {
        position: absolute;
        content: '';
        height: 19px;
        width: 19px;
        left: 1px;
        bottom: 1px;
        background-color: #27ab9e;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
    input {
      &:checked + .slider {
        background-color: $white;
      }
      &:focus + .slider {
        box-shadow: 0 0 1px $white;
      }
      &:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
      }
    } /* Rounded sliders */

    .slider.round {
      border-radius: 20px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  .plans-table {
    display: none;
    margin-top: 32px;
    margin-bottom: 0;
  }
  .plans-holder {
    margin: 32px 0 0;
    font-family: Inter, sans-serif;
    font-feature-settings: 'ss01' on;

    .plans-headers,
    .plans-content,
    .plans-footer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 4px;

      // &.starter-plus {
      //   display: block;
      //   padding: 0;
      //   margin: 0;
      //   color: $orangeLightest;
      //   background-color: $white;
      //   border-top: 4px solid rgba(249, 248, 244, 0.7);
      // }
    }

    .select-plans-headers,
    .select-plans-content,
    .select-plans-footer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 4px;
    }

    .select-plans-headers,
    .plans-headers {
      margin-bottom: 10px;
      & > * {
        position: relative;
        padding: 16px 16px 24px;
        text-align: center;
        background: white;
        font-weight: bold;

        &.popular {
          color: $red;
          &:before {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            right: 0;
            height: 10px;
            background: white;
          }
          &:after {
            content: 'MOST POPULAR';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate3d(-50%, 50%, 0);
            padding: 4px 8px;
            border-radius: 16px;
            background: $red;
            color: white;
            font-size: 10px;
            line-height: 1em;
            font-weight: bold;
          }
          .pricing {
            color: $red;
          }
        }
        .pricing {
          display: inline-flex;
          position: relative;
          align-items: baseline;
          justify-content: center;
          font-size: 32px;
          line-height: 1em;
          font-weight: bold;
          margin-top: 8px;
          color: inherit;

          &:after {
            content: attr(data-old-price);
            position: absolute;
            top: 4px;
            right: 0;
            text-decoration: line-through;
            font-size: 10px;
            line-height: 1em;
            letter-spacing: 0.04em;
            font-weight: normal;
            color: $gray;
          }
          .sign {
            align-self: flex-start;
            font-size: 20px;
            line-height: 1em;
            margin-right: 2px;
          }
          .amount {
            letter-spacing: -0.03em;
          }
          .period {
            font-size: 10px;
            line-height: 1em;
            letter-spacing: 0.04em;
            margin-left: 4px;
          }
        }
      }
    }

    .select-plans-content,
    .plans-content {
      .plans-row {
        display: contents;

        &:not(.empty):hover {
          & > * {
            background: #fef7f5;
          }
        }
        & > * {
          background: white;
          font-size: 11px;
          line-height: 1em;
          text-align: center;
          padding: 12px;

          &.bold {
            font-weight: 600;
          }
          &.bolder {
            font-weight: 800;
          }
          &.off {
            color: $grayLighter;
            text-decoration: line-through;
          }
        }
      }
    }
    .select-plans-footer,
    .plans-footer {
      position: sticky;
      bottom: 0;
      background: #f9f8f4;
      z-index: 10;

      .plan-button {
        display: block;
        margin: 16px;
        height: 36px;
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
        line-height: 36px;
        font-weight: bold;
        letter-spacing: 0.11em;
        text-transform: uppercase;
        color: $red;
        background: transparent;
        border: 1px solid rgba($red, 0.5);
        cursor: pointer;

        &:not(.popular):hover {
          border: 1px solid $red;
        }
        &.popular {
          color: white;
          background: $red;
          box-shadow: 0px 0.0483822px 0.8479px rgba(241, 88, 48, 0.120907),
            0px 0.414063px 1.78191px rgba(241, 88, 48, 0.173047),
            0px 1.26083px 3.28957px rgba(241, 88, 48, 0.216953),
            0px 3.01108px 6.86823px rgba(241, 88, 48, 0.269093),
            0px 9px 31px rgba(241, 88, 48, 0.39);
        }
      }
    }
    .disclaimer {
      font-size: 12px;
      text-align: center;
      font-weight: bolder;
      color: $orange;

      &.footer {
        color: $grayDark;
        text-align: right;
      }
    }
  }
  .thborder {
    border: 1px solid #e0e0e0;
  }
  .pl8 {
    padding-left: 8px;
    color: lightgray;
  }
  .c1 {
    width: 212px;
  }
  .c2 {
    width: 175px;
  }
  .c3 {
    width: 210px;
  }
  .trborder {
    padding-left: 18px;
    padding-top: 20px;
    text-align: center;
    border: none;
    font-size: 14px;
  }
  .check {
    color: #27ab9e;
    font-size: 15px;
  }
  .h40 {
    height: 40px;
  }
  .cross {
    color: #d0021b;
    font-size: 15px;
  }
  .bold {
    font-weight: bolder;
    font-size: 25px;
  }
  .bolder {
    color: #636363;
    font-size: 13px;
  }
  .sm {
    padding-right: 30px;
    font-size: 12px;
  }
  thead {
    div.sm {
      .package-pricing {
        .monthly {
          display: block;
        }
        .yearly {
          display: none;
        }
        &.yearly {
          .monthly {
            display: none;
          }
          .yearly {
            display: block;
          }
        }
      }
    }
  }
  .prime {
    background-color: #27ab9e;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }
  .back {
    background-color: #dfeceb;
  }
  .fl {
    padding-left: 30px;
    float: left;
    font-size: 12px;
    color: #e8562a;
  }
  th {
    padding-left: 10px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    text-align: left;

    div {
      color: #636363;
      font-size: 25px;
      text-align: center;
    }
  }
  td {
    border: 1px solid #e0e0e0;
    font-size: 15px;
    padding-left: 10px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    color: #636363;
    text-align: center;
  }
  .site-title {
    text-align: center;
    font-size: 16px;
    width: 100%;
  }
  .billing-options {
    text-align: center;
    width: 30.5em;
    margin: 1.5em auto;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
      border: none;
      margin-left: auto;
      margin-right: auto;
      vertical-align: bottom;
      padding-bottom: 2em;
    }
    .switch-label {
      &.popular {
        color: $red;
      }
    }
    &:after {
      top: 0em;
      right: 5em;
      width: 89px;
      height: 53px;
      transform: rotate(-15deg);
      color: #636363;
      font-size: 13px;
      line-height: 115%;
      position: absolute;
      content: 'Get 2 months for free';
      padding-top: 0.67em;
      border-radius: 4px;
      text-transform: uppercase;
      background-color: rgba(248, 231, 28, 0.35);
    }
  } /*-----------  Packages  -----------*/

  .package-block {
    margin-bottom: 0.5em;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
    flex-flow: row nowrap;
    display: flex;

    > * {
      padding-left: 25px;
    }
    .package-title {
      .package-price {
        &.monthly {
          display: block;
        }
        &.yearly {
          display: none;
        }
      }
      &.yearly {
        .package-price.monthly {
          display: none;
        }
        .package-price.yearly {
          display: block;
        }
      }
    }
    .package-features {
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-flow: row nowrap;
      display: flex;

      ul {
        margin: -1em 0;
        color: $grayLight;
        padding-left: 3em;

        li {
          width: 50%;
          color: $gray;
          padding: 0.85em;
          text-align: left;
          font-size: 12.8px;
          line-height: 1.88;
          padding-top: 0px;
          padding-bottom: 0px;
          position: relative;

          &:before {
            top: 45%;
            left: -13px;
            width: 3px;
            height: 3px;
            content: '';
            position: absolute;
            border-radius: 10em;
            background-color: $grayLight;
          }
        }
        li.placeholder-list-item {
          color: transparent;
          list-style: none;

          &:before {
            background-color: transparent;
          }
        }
      }
      .package-status {
        text-align: center;
        padding-right: 1em;
        position: relative;
        flex: 1 0 25%;

        p {
          color: $gray;
          font-size: 0.75em;
          line-height: 145%;
          margin: 0 0.5em 0.75em;
        }
        .button {
          display: block;
          font-size: 0.85em !important;

          &[disabled] {
            color: $gray;
            cursor: default;
            background-color: transparent;
            border: 2px solid $grayLightest;
          }
          &[disabled].disabled-promo {
            color: $gray;
            cursor: default;
            background-color: $white;
            border: 1px solid $blue;
            border-radius: 0;
          }
        }
        .email-links {
          display: none;
          font-size: 0.85em !important;
          background-color: $blue;
          padding: 5px;

          a {
            color: $white;
            word-wrap: break-word;
          }
        }
      }
    } /*-----------  Pro Package  -----------*/

    &.pro {
      .package-features {
        ul {
          padding-bottom: 10px;

          li {
            padding-top: 5px;
            padding-bottom: 5px;

            .info {
              position: relative;
              top: 6px;
            }
            .info-plus {
              position: relative;
              top: 11px;
            }
            i {
              top: 18%;
              left: -1em;
              color: $green;
              font-size: 1.67em;
              position: absolute;
            }
            &:before {
              display: none;
            }
          }
          li.thin {
            padding-bottom: 0px;
          }
          li.adjusted-down {
            margin-top: 10px;
          }
        }
      }
    } /*-----------  Elite Package  -----------*/

    &.elite {
      .button {
        color: $white;
        font-size: 0.95em;
        border-color: $red;
        padding-top: 0.67em;
        padding-bottom: 0.67em;
        background-color: $red;
      }
    } /*-----------  Premium Package  -----------*/

    &.premium {
      .button {
        color: $white;
        font-size: 0.95em;
        border-color: $red;
        padding-top: 0.67em;
        padding-bottom: 0.67em;
        background-color: $red;
      }
    }
  } /*-----------  AB TEST: Company Logos  -----------*/

  .with-logos {
    .billing-options:after {
      top: -2em;
      right: -2.5em;
      width: 4em;
      height: 4em;
      padding-top: 0.9em;
      background-color: $orange;
      border: 1px solid $white;
      box-shadow: 0 0 7px $orange;
    }
    .package-block {
      &.pro {
        .package-features {
          .flex-container {
            padding-top: $gutterSm;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-flow: row nowrap;
            display: flex;

            ul li {
              padding-right: 3em;
              vertical-align: top;

              i.icon-target {
                top: 10%;
              }
            }
          }
        }
      }
    }
  }
  .tooltip-inner {
    font-size: 12px;
    color: white;
    background-color: #373737;
  }
  .center {
    text-align: center;
  }
  .learn-more {
    color: #e8562a;
  }

  .switch-label {
    cursor: pointer;
  }
}

.dialog-closeable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
