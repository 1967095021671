/*-----------  Inline-Block (w/ alignment)  -----------*/

@mixin inline-block($alignment: middle) {
  display: -moz-inline-stack;
  display: inline-block;


  @if $alignment and $alignment != none {
    vertical-align: $alignment;
  }
}

/*-----------  Decoration  -----------*/

@mixin striped-bg($bg-color: $grayLightest, $darken-amount: 4%, $stripe-color: darken($bg-color, $darken-amount)) {
  background-size: 40px 40px;
  background-color: $bg-color;
  background-image: linear-gradient(-45deg, $stripe-color 25%, transparent 25%, transparent 50%, $stripe-color 50%, $stripe-color 75%, transparent 75%, transparent);
}

/*-----------  Type Effects  -----------*/

@mixin ellipsis($width: 100%) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*-----------  Vertical Alignment  -----------*/

@mixin vertical-align() {
  top: 50%;
  transform: translateY(-50%);
}

/*-----------  Horizontal Alignment  -----------*/

@mixin horizontal-align() {
  left: 50%;
  transform: translateX(-50%);
}

/*-----------  Center Alignment  -----------*/

@mixin center-align($position: absolute) {
  top: 50%;
  left: 50%;
  position: $position;
  transform: translate(-50%, -50%);
}

/*-----------  Arrows  -----------*/

@mixin left-arrow($height: 1em, $color: $grayLighter) {
  &:after {
    content: '';
    width: 0;
    height: 0;
    z-index: 10;
    margin: 0 auto;
    display: block;
    position: absolute;
    left: -$height * 0.9;
    top: calc(50% - #{$height});
    background-color: transparent;
    border-top: $height solid rgba(255, 255, 255, 0);
    border-bottom: $height solid rgba(255, 255, 255, 0);
    border-right: $height solid $color;
    -moz-transform: scale(1.01);
    transition: all 0.15s ease-out 0s;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/*-----------  Weight Corrections  -----------*/

@mixin antialiased($smooth: true) {
  @if $smooth {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
  }
}
