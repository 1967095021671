@import './variables';
@import './mixins';

/*-----------  Site Installation  -----------*/

#sites-show.installation-page,
#sites-install.installation-page {
  margin: 0 auto;
  max-width: $blockWidth;

  header {
    max-width: 42em;
    text-align: center;
    margin: 0 auto 3em;

    h1 {
      margin-bottom: 0.5em;
    }
  }

  > h5 {
    font-size: 1em;
    color: $grayLight;
  }

  pre {
    white-space: normal;
    font-weight: $fontWeightLight;
  }

  /*-----------  Wordpress Reveal  -----------*/

  .wordpress-reveal {

    .title {
      margin: 1em 0;
      color: $grayDarker;
      font-weight: $fontWeightBold;
      @include antialiased();
    }

    strong {
      display: block;
      color: $grayDarker;
      font-weight: $fontWeightBold;
      @include antialiased();
    }

    strong + p {
      margin: 0.5em 1em 1.5em;
    }

    .button {
      .icon-download {
        font-size: 1.33em;
      }

      padding: 0.45em 1.25em;
      margin-left: 0.2em;
    }

    small {
      top: -0.5em;
      color: $grayLighter;
      position: relative;
    }

    .form-inline {
      input {
        width: 25em;
        margin: 0;
      }
    }
  }

  /*-----------  Wix Reveal  -----------*/

  .wix-reveal {
    padding-top: 0;

    .title {
      margin: 1em 0;
      color: $grayDarker;
      font-weight: $fontWeightBold;
      @include antialiased();
    }

    strong + p {
      margin: 0.5em 1em 1.5em;
    }

    .button {
      padding: 0.45em 1.25em;
      margin-left: 0.2em;
    }

    small {
      top: -0.5em;
      color: $grayLighter;
      position: relative;
    }

    .form-inline {
      input {
        width: 25em;
        margin: 0;
      }
    }
  }

  /*-----------  Developer Reveal  -----------*/

  .developer-reveal {
    form {
      position: relative;

      label {
        width: 5em;
        left: -6.25em;
        color: $grayLight;
        font-size: 0.75em;
        text-align: right;
        position: absolute;
      }

      .topper {
        margin-top: 2em;
      }
    }
  }
}


.clipboard-btn {
  color: white;
  font-size: 0.95em;
}
