@use '../../shared/Button.scss';
@import './variables';
@import './mixins';

#rule-lists {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  table {
    td:first-of-type {
      color: #e8562a;
      width: 35%;
    }

    td:last-of-type {
      width: 15em;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .action-wrapper.center {
      justify-content: center;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
      margin-right: 11px;
      background-color: white;
      border: 1px solid rgba(0,0,0,0.18);
      padding: 4px;
    }
  }
}