@import 'variables';

.ServicePurchaseModal {
  .MuiPaper-root {
    color: #000;
    padding: 0;
    max-width: 800px;
    min-height: 480px;

    .MuiDialogContent-root {
      background-color: #ff4200;
      padding: 0;

      .title {
        color: #fff;
        font-size: 1.5em;
        font-weight: bold;
        padding: 10px;

        .cross {
          float: right;
          cursor: pointer;
        }
      }

      .poster {
        position: relative;
      }

      .thanks {
        height: 100%;
        background-color: #e2e5e2;
        text-align: center;
        font-size: 1.6em;
        line-height: 1.3em;
        letter-spacing: 1px;
        padding: 30px;
        display: flex;
        flex-direction: column;

        div {
          margin: 25px;
        }

        .bold {
          font-weight: bolder;
        }
      }
    }

    .submit {
      cursor: pointer;
      text-align: center;
      position: absolute;
      width: 50%;
      background-color: #ff4200;
      color: #ffffff;
      font-weight: bolder;
      padding: 10px 0;
      bottom: 50px;
      left: 20px;
      font-size: 1.5em;

      .MuiSvgIcon-fontSizeLarge {
        vertical-align: middle;
      }
    }

    .blurb {
      color: #818181;
      background-color: #333333;
      font-size: 0.75em;
      padding: 5px;
      display: flex;
      span {
        width: 50%;
      }
    }

    .no-thanks {
      text-align: center;
      margin: 10px 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
