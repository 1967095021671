/*-----------  Body  -----------*/
*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin: 0 !important;
}

@media screen and (max-width: 600px) {
  html,
  body {
    min-width: auto;
  }
}

#root .hb-editor #toolbar {
  margin: auto;
  height: 39px;
  --disabled-color: #a8a8a8;
  --active-color: #4f4f4f;
  --hover-color: #a8a8a8;
  --enabled-color: #404040;
  --button-color: #4f4f4f;
  --button-secondary-color: #a8a8a8;

  .tox.tox-tinymce-aux {
    .tox-menu {
      background: var(--active-color);
    }

    .tox-dialog,
    .tox-dialog__header,
    .tox-dialog__footer,
    .tox-textfield,
    .tox-listboxfield .tox-listbox--select {
      background: white;
      color: black;
      border-color: var(--enabled-color);
      .tox-label,
      .tox-dialog__body-nav-item {
        color: black;
      }
      .tox-dialog__body-nav-item--active {
        border-color: black;
      }
    }
    .tox-textarea {
      background: var(--active-color);
    }
    .tox-textfield:focus,
    .tox-textarea:focus {
      box-shadow: none;
    }
    .tox-button {
      border: none;
      background: var(--button-color);
    }
    .tox-button--secondary {
      background: var(--button-secondary-color);
    }
    .tox-collection__item--active {
      background: var(--hover-color);
    }

    .tox-collection__item--enabled {
      background: var(--enabled-color);
    }

    .tox-pop__dialog {
      background: var(--active-color);
      border-color: var(--active-color);
      .tox-tbtn:hover {
        background: var(--hover-color);
      }
    }
    .tox-split-button:focus,
    .tox-split-button:hover {
      background: var(--hover-color);
      box-shadow: 0 0 0 1px var(--hover-color) inset;
    }
    .tox-pop.tox-pop--left::before,
    .tox-pop.tox-pop--left::after {
      border-color: transparent var(--active-color) transparent transparent;
    }
  }

  .tox.tox-tinymce {
    .tox-editor-header {
      border: none;
    }
    .tox-toolbar__overflow,
    .tox-toolbar__primary,
    .tox-toolbar-overlord,
    .tox-editor-header,
    .tox-menu,
    .tox-tbtn--bespoke {
      background: var(--active-color);
    }
    .tox-tbtn:hover,
    .tox-tbtn:active,
    .tox-tbtn:focus,
    .tox-tbtn--enabled,
    .tox-split-button:focus,
    .tox-split-button:hover {
      background: var(--hover-color);
      box-shadow: 0 0 0 1px var(--hover-color) inset;
    }
    .tox-toolbar__group {
      padding: 0 5px 0 5px;
    }
  }
  .tox.tox-tinymce.tox-tinymce--disabled {
    .tox-toolbar__primary,
    .tox-toolbar-overlord,
    .tox-editor-header,
    .tox-menu,
    .tox-tbtn--bespoke {
      background: var(--disabled-color);
    }
  }
}

#hellobar-preview-container.inline-preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-toolbar {
  background-color: rgb(82, 82, 82);
  color: #ffffff;
  height: 51px;
  bottom: 0;
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .popup-page-selector {
    max-width: 300px;
    width: fit-content;
    display: flex;
    height: 52px;
    align-items: center;
    margin-left: 35px;
  }
}
