@import "variables";

.EditRuleDialog-content {

  /*-----------  Conditions Wrapper  ----------- */

  .conditions-wrapper {
    margin-top: 20px;
    padding: 20px;
    border-radius: $radiusSm;
    border: 1px solid $red;

    .condition-block {
      font-size: 95%;
      margin-bottom: 5px;

      .condition-ruleset {
        input,
        .select-wrapper {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }

        .MuiFormControl-root {
          margin-right: 1em;
          margin-bottom: 5px;
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 8.5px;
          width: 130px;
        }

        .css-zsx00q-MuiButtonBase-root-MuiIconButton-root {
          padding: 4px 5px;
        }
        /*-----------  Condition Type  ----------- */

        .rule_conditions_segment {
          width: 25%;

          select {
            text-transform: capitalize;
          }
        }

        /*-----------  Condition Operand  ----------- */

        .rule_conditions_operand {
          width: 21%;
        }

        /*-----------  Condition Choices  ----------- */

        .choice-wrapper {
          span {
            margin: 0 5px;
          }
        }

        .rule_conditions_choices {
          width: 48%;
          text-align: left;

          .select-wrapper {
            width: 67%;
            margin-left: 0 !important;

            &.time-select {
              width: 16%;

              &.timezone {
                width: 63%;
              }
            }
          }

          input {
            &.between {
              width: 41.75%;
              transition: margin-left 0;
            }

            &:not(.between) {
              width: 100%;
            }

            &.small {
              width: 41.75%;
            }

            &[type="date"] {
              padding-bottom: 0.33em;
              display: -webkit-inline-flex;

              &::-webkit-calendar-picker-indicator {
                float: right;
                margin-top: 2%;
                display: inline-block;
              }

              &::-webkit-clear-button,
              &::-webkit-inner-spin-button {
                margin: 0;
                -webkit-appearance: none;
              }
            }
          }

          input.between:not([disabled]) ~ input.between:not([disabled]) {
            margin-left: 4%;
          }

          .and-interjection {
            color: $gray;
            margin-left: 4%;
            font-size: 0.85em;
          }

          .multiple-input-wrapper {
            position: relative;
            margin-top: 0.15em;

            span {
              top: 0;
              left: -2em;
              color: $grayLight;
              font-size: 0.9em;
              line-height: 250%;
              position: absolute;
            }

            input {
              padding-right: 3.5em;
            }

            .multiple-choice-actions {
              top: 0;
              right: 0.67em;
              position: absolute;

              a {
                color: $grayLight;
                font-size: 1.15em;
                font-weight: $fontWeightBold;
                line-height: 200%;
              }

              .choice-add:hover {
                color: $green;
              }

              .choice-remove {
                top: -1px;
                position: relative;
                margin-right: 0.33em;

                &:hover {
                  color: $red;
                }
              }
            }

            &:first-child {
              margin-top: 0;

              span,
              .choice-remove {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  /*-----------  Condition Actions  ----------- */

  .condition-actions {
    text-align: end;

    span {
      width: 1.45em;
      color: $white;
      font-size: 1.5em;
      text-align: center;
      line-height: 1.45em;
      margin-left: 0.25em;
      border-radius: $radiusSm;
      display: inline-block;
      cursor: pointer;

      &.condition-remove {
        background-color: $grayLight;

        &:hover {
          background-color: darken($grayLight, 15%);
        }
      }

      &.condition-add {
        background-color: $blue;

        &:hover {
          background-color: darken($blue, 15%);
        }
      }
    }
  }

  .condition-ruleset {
    .MuiFormControl-root {
      .MuiInputBase-root {
        margin-top: 10px;
      }
      .MuiInputBase-root:first-child {
        margin-top: 0;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 20px;
  }
}
